import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useBoolean } from '../../utils/hooks';
import Layout from '../../components/layout';
import { forUsPages } from '../../data/data';
import loadable from '@loadable/component';

const SubMenu = loadable(() => import('../../components/subMenu'));
const Grid = loadable(() => import('../../components/grid'));
const CardWithImage = loadable(() => import('../../components/card-with-image'));
const PhotoSwipeWrapper = loadable(() => import('../../components/photoSwipe'));

const Competitions = ({ data, location }) => {
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [largeImage, setLargeImage] = useState({
    src: '',
    h: 0,
    w: 0
  });
  const competitions = data.allMarkdownRemark.edges;
  const handleOpen = image => {
    setLargeImage({
      src: image.src,
      h: image.presentationHeight,
      w: image.presentationWidth
    });

    showModal();
  };

  const metaData = {
    title: 'Конкурси'
  };

  return (
    <Layout metaData={metaData}>
      <SubMenu location={location.pathname} sectionItems={forUsPages} />
      <Grid>
        {competitions.map((item, i) => {
          const competition = item.node.frontmatter;
          return (
            <CardWithImage
              key={i}
              info={competition.info}
              url={competition.project}
              image={competition.largeImage.childImageSharp.fluid}
              onOpen={() => {
                handleOpen(competition.largeImage.childImageSharp.fluid);
              }}
            />
          );
        })}
      </Grid>
      <PhotoSwipeWrapper isOpen={isVisible} items={[largeImage]} onClose={hideModal} />
    </Layout>
  );
};

export default Competitions;

export const query = graphql`
  query competitions {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/за-нас/конкурси/" } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            info
            project
            thumbnailImage {
              ...mediumImage
            }
            largeImage {
              childImageSharp {
                ...largeGalleryImage
              }
            }
          }
        }
      }
    }
  }
`;
